<template>
  <span class="select-container">
    <span v-if="fetched" class="select-combobox">
      <select
        v-if="options.length"
        class="form-control"
        v-model="selected"
        :disabled="disabled"
      >
        <option v-if="nullable" :value="null"></option>
        <option v-for="option in options" :key="option[val]" :value="option">
          {{ option[label] }}
        </option>
      </select>
      <select v-else class="form-control" disabled>
        <option selected>{{$t('sms.infos.lbl-not-found')}}</option>
      </select>
    </span>
    <div v-else class="preloader pl-xxs pls-primary">
      <svg class="pl-circular" viewBox="25 25 50 50">
        <circle class="plc-path" cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  </span>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    val: {
      type: String,
      default: 'id',
    },
    nullable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      fetched: false,
    };
  },
  watch: {
    options(val) {
      if (val) {
        this.fetch();
      }
    },
    selected(selectedValue) {
      this.$emit('select', selectedValue);
      this.$emit('input', selectedValue[this.val]);
    },
  },
  methods: {
    fetch() {
      if (!this.nullable && !this.value) {
        this.selected = this.options[0];
      } else {
        Object.entries(this.options).forEach(([key, option]) => {
          console.log(option[this.val]);
          console.log(this.value);
          if (option[this.val] === this.value) {
            this.selected = option[this.val];
          }
        });
      }
      this.fetched = true;
    },
  },
};
</script>

<style lang="scss">
</style>
