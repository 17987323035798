<template>
  <div class="content block-el">
    <PageHeader :title="$t('adm.create.header')" />

    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="create">
                <h5 class="card-title">{{ $t('properties.company') }}</h5>
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="name">{{ $t('adm.create.comp-name') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.name"
                      required
                    />
                  </div>
                  <div class="form-group col-2">
                    <label for="name">{{ $t('app.country') }}</label>
                    <select v-model="form.country" class="form-control">
                      <option
                        v-for="(country, code) in $store.state.countries"
                        :key="code"
                        :value="code"
                      >
                        {{ country }}
                      </option>
                    </select>
                  </div>
                  <div v-if="form.country == 'BR'" class="form-group col-2">
                    <label for="name">CNPJ</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.tax_id"
                      v-mask="'##.###.###/####-##'"
                      :required="form.type != 'trial'"
                      :disabled="searchingCompany"
                      @input="searchCompany"
                    />
                    <div
                      v-if="searchingCompany"
                      class="preloader pl-xxs pls-primary"
                    >
                      <svg class="pl-circular" viewBox="25 25 50 50">
                        <circle
                          class="plc-path"
                          cx="50"
                          cy="50"
                          r="20"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                  <div v-else class="form-group col-2">
                    <label for="name">Tax ID</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Tax ID"
                      v-model="form.tax_id"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-5">
                    <label for="name">{{
                      $t('adm.create.corporate-name')
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.company_name"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label for="name">{{
                      $t('adm.create.billing-email')
                    }}</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="form.billing_email"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="control-label text-left">{{
                      $t('generic-str.menu.anti-fraud')
                    }}</label>
                    <select
                      class="form-control"
                      v-model="form.antifraud"
                      required
                    >
                      <option selected value="open">
                        {{ $t('generic-str.status.lbl-open') }}
                      </option>
                      <option value="moderate">
                        {{ $t('generic-str.status.lbl-moderate') }}
                      </option>
                      <option value="strict">
                        {{ $t('generic-str.status.lbl-strict') }}
                      </option>
                    </select>
                  </div>
                </div>
                <h5 class="card-title">{{ $t('generic-str.contract') }}</h5>
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="name">{{ $t('generic-str.type') }}</label>
                    <select class="form-control" v-model="form.type">
                      <option value="trial">
                        {{ $t('acc-settings.trial') }}
                      </option>
                      <option value="prepaid">
                        {{ $t('adm.customer.assign.prepaid') }}
                      </option>
                      <option value="weekly">
                        {{ $t('adm.customer.assign.weekly') }}
                      </option>
                      <option value="biweekly">
                        {{ $t('adm.customer.assign.biweekly') }}
                      </option>
                      <option value="monthly">
                        {{ $t('adm.customer.assign.monthly') }}
                      </option>
                    </select>
                  </div>
                  <div v-if="form.type != 'trial'" class="form-group col-md-3">
                    <label v-if="form.type == 'prepaid'" for="name">{{
                      $t('generic-str.funds')
                    }}</label>
                    <label v-else for="name">{{
                      $t('generic-str.limit')
                    }}</label>
                    <input
                      v-model="form.balance"
                      v-money="money"
                      class="form-control"
                      style="text-align: right"
                    />
                  </div>
                  <div v-else class="form-group col-md-3">
                    <label for="name">{{
                      $tc('adm.customer.assign.credit', 2)
                    }}</label>
                    <input
                      v-model="form.credits"
                      type="number"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="name">{{ $t('generic-str.expiration') }}</label>
                    <input
                      v-model="form.due_date"
                      type="number"
                      class="form-control"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="name">{{
                      $t('home.invite-card.select.sale-executive')
                    }}</label>
                    <base-select
                      v-model="form.salesId"
                      :options="salesExecutives"
                      :label="'name'"
                    />
                  </div>
                </div>
                <h5 class="card-title">
                  {{ $t('app.address') }}
                </h5>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="name">{{ $t('generic-str.zipcode') }}</label>
                    <input
                      type="tel"
                      class="form-control"
                      :placeholder="$t('generic-str.zipcode')"
                      v-model="form.postal_code"
                      v-mask="'#####-###'"
                      :required="form.type != 'trial'"
                    />
                    <div
                      v-if="searching"
                      class="preloader pl-xxs pls-primary loading-cep"
                    >
                      <svg class="pl-circular" viewBox="25 25 50 50">
                        <circle class="plc-path" cx="50" cy="50" r="20" />
                      </svg>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{ $t('app.address') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('app.address')"
                      v-model="form.street"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{
                      $tc('generic-str.lbl-number', 1)
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      :placeholder="$tc('generic-str.lbl-number', 1)"
                      v-model="form.number"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{ $t('generic-str.complement') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('generic-str.complement')"
                      v-model="form.complement"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{
                      $t('generic-str.neighborhood')
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('generic-str.neighborhood')"
                      v-model="form.district"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{ $t('generic-str.city') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('generic-str.city')"
                      v-model="form.city"
                      :required="form.type != 'trial'"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="name">{{ $t('generic-str.state') }}</label>
                    <select v-model="form.province" class="form-control">
                      <option
                        v-for="(province, code) in $store.state.provinces"
                        :key="code"
                        :value="code"
                      >
                        {{ province }}
                      </option>
                    </select>
                  </div>
                </div>
                <span>
                  <h5 class="card-title">
                    {{ $t('generic-str.responsible') }}
                  </h5>
                  <div class="form-row">
                    <div class="form-group col-md-3">
                      <label for="name">E-Mail</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="form.owner.email"
                        required
                      />
                    </div>
                    <div class="form-group col-4">
                      <label for="name">{{ $tc('generic-str.name', 1) }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.owner.name"
                        required
                      />
                    </div>
                    <div class="form-group col-5">
                      <label for="name">{{
                        $t('generic-str.lbl-cellphone')
                      }}</label>
                      <tel-input
                        ref="mobile_number"
                        v-model="form.owner.mobile_number"
                        required
                      />
                    </div>
                    <div class="form-group col-5">
                      <label for="name">{{ $t('generic-str.password') }}</label>
                      <password-input
                        ref="password"
                        v-model="form.owner.password"
                      />
                    </div>
                  </div>
                </span>
                <custom-button :loading="creating">
                  {{ $t('app.create') }}
                </custom-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import UserService from '@/services/user.service';
import { mask } from 'vue-the-mask';
import TelInput from '@/components/form/TelInput.vue';
import PasswordInput from '@/components/form/PasswordInput.vue';
import CustomButton from '@/components/CustomButton.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';

export default {
  name: 'CreateCustomer',
  components: {
    PageHeader,
    TelInput,
    PasswordInput,
    CustomButton,
    BaseSelect,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      creating: false,
      isSending: false,
      searchingCompany: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      salesExecutives: null,
      form: {
        country: 'BR',
        antifraud: 'moderate',
        type: 'trial',
        balance: 0,
        credits: 0,
        salesId: null,
        owner: {
          email: '',
          mobile_number: '',
        },
      },
      searching: false,
      pages: 1,
    };
  },
  created() {
    this.fetchSalesExecutives();
  },
  methods: {
    fetchSalesExecutives() {
      UserService.getUsers({
        role: 'sales',
      }).then(
        (salesExecutives) => {
          this.salesExecutives = salesExecutives;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    create() {
      if (this.$refs.password.valid_password) {
        this.creating = true;
        CustomerService.createCustomer(this.form)
          .then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('adm.create.customer'),
                type: 'success',
              });
              this.$router.push('./');
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.message,
                type: 'danger',
              });
            },
          )
          .finally(() => {
            this.creating = false;
          });
      } else {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} 422`,
          content: this.$t('warnings.invalid-password'),
          type: 'danger',
        });
      }
    },
    searchCompany() {
      if (this.form.tax_id.length >= 18 && !this.searchingCompany) {
        this.searchingCompany = true;
        this.$store
          .dispatch('searchCompany', { taxId: this.form.tax_id })
          .then((response) => {
            this.form.postal_code = response.address.postalCode;
            this.form.street = response.address.street;
            this.form.number = response.address.number;
            this.form.complement = response.address.complement;
            this.form.district = response.address.district;
            this.form.city = response.address.city;
            this.form.province = response.address.province;
            this.form.company_name = response.companyName;
            this.form.billing_email = response.email;
          })
          .catch((error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.message,
              type: 'danger',
            });
          })
          .finally(() => {
            this.searchingCompany = false;
          });
      }
    },
    searchAddress() {
      if (this.form.postal_code && this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 40px;
  right: 10px;
  &.pls-primary .plc-path {
    stroke: #635ebe;
  }
}
</style>
