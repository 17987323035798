<template>
  <div class="form-group">
    <label for="inputEmail" class="sr-only" :style="'textTransform: uppercase'">{{$t('generic-str.password')}}</label>
    <div class="input-group mb-3">
      <input
        :type="type"
        id="inputPassword"
        class="form-control"
        :placeholder="$t('generic-str.password')"
        required
        @input="checkPassword"
        v-bind:class="{'invalid':(checked && !valid_password)}"
        :disabled="disabled"
      />
      <div class="input-group-append">
        <button class="btn btn-primary btn-icon" type="button" @click="toggle">
          <i :class="iconClasses"></i>
        </button>
      </div>
    </div>
    <ul class="list-unstyled">
      <li>
        <i v-if="contains_lowercase" class="icon dripicons-checkmark text-success"></i>
        <i v-else class="icon dripicons-cross"></i>
        <small>{{$t('register.steps.lowercase')}}</small>
      </li>
      <li>
        <i v-if="contains_uppercase" class="icon dripicons-checkmark text-success"></i>
        <i v-else class="icon dripicons-cross"></i>
        <small>{{$t('register.steps.uppercase')}}</small>
      </li>
      <li>
        <i v-if="contains_number" class="icon dripicons-checkmark text-success"></i>
        <i v-else class="icon dripicons-cross"></i>
        <small>{{$t('register.steps.number')}}</small>
      </li>
      <li>
        <i
          v-if="contains_special_character"
          class="icon dripicons-checkmark text-success"
        ></i>
        <i v-else class="icon dripicons-cross"></i>
        <small>{{$t('register.steps.special-char')}}</small>
      </li>
      <li>
        <i v-if="valid_length" class="icon dripicons-checkmark text-success"></i>
        <i v-else class="icon dripicons-cross"></i>
        <small>{{$t('register.steps.char-numbers')}}</small>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props: ['value', 'disabled'],
  data() {
    return {
      type: 'password',
      checked: false,
      valid_length: false,
      contains_number: false,
      contains_lowercase: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
    };
  },
  mounted() {
    this.$root.$on('input.pass.check', this.checkEvent);
  },
  computed: {
    iconClasses() {
      return [
        'fas',
        {
          'fa-eye-slash': this.type === 'text',
          'fa-eye': this.type === 'password',
        },
      ];
    },
  },
  methods: {
    checkEvent() {
      this.checked = true;
      console.log('Verificado');
    },
    checkPassword(e) {
      this.checked = true;
      this.$emit('input', e.target.value);

      this.valid_length = e.target.value.length >= 12;
      this.contains_number = /[0-9]/.test(e.target.value);
      this.contains_lowercase = /[a-z]/.test(e.target.value);
      this.contains_uppercase = /[A-Z]/.test(e.target.value);
      this.contains_special_character = /[!@#$%&]/.test(e.target.value);

      // Valid to envent
      if (this.valid_length && this.contains_number && this.contains_lowercase && this.contains_uppercase && this.contains_special_character) {
        this.valid_password = true;
        this.$root.$emit('input.pass.valid', this.valid_password);
      } else {
        this.valid_password = false;
      }
    },
    toggle() {
      this.type = this.type === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style lang="scss">
.form-control.invalid {
  border-color: #ff5c75;
}
</style>
